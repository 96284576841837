import Network from '@/services/network';
import {ref} from 'vue';

export default function handleDesignations() {
    const network = new Network;
    const loading = ref(false);
    const uri = '/procurement/organization-setting/designation';

    const fetchDesignationList = (query) => {
        return network.api('get', uri + query);
    }

    const createNewDesignation = (data) => {
        return network.api('post', uri, data);
    }

    const updateDesignation = (id, data) => {
        return network.api('put', `${uri}/${id}`, data);
    }


    return {
        loading,
        fetchDesignationList,
        createNewDesignation,
        updateDesignation,
    }
}