<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="true"
          title="Designation List"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
      />
      <div class="row">
        <div class="col-10">
          <label for="colFormLabel" class="col-form-label">Search</label>
          <input class="form-control" type="text" name="status" v-model="quick_search">
        </div>
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              @click="onClickSearchButton"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
          >Go
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 mt-2">
      <div class="d-flex justify-content-end px-2">
        <button
            @click="exportTable"
            class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>
    <ListTable
        :designations="designationList"
        @onEdit="onEditDesignation"
    />
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>

    <DesignationAddModal
        :modalTitle="designation.id ? 'Edit Designation' : 'Add Designation'"
        :showParent="true"
        ref="designation"
        :formData="designation"
        @onSubmitForm="handleDesignationSubmit"
        @onCloseDesignationModal="resetForm"
    />
  </div>
</template>

<script>
import TitleButton from '@/components/atom/TitleButton'
import ListTable
  from '@/components/molecule/procurement/organization-settings/admin-setup/designation/DesignationListingTable.vue'
import DesignationAddModal
  from '@/components/molecule/procurement/organization-settings/admin-setup/designation/DesignationAddModal.vue'

import Pagination from '@/components/atom/Pagination'
import Loader from '@/components/atom/LoaderComponent'
import {inject} from "vue";
import handleDesignations from "@/services/modules/procurement/organization-settings/designation";

export default {
  name: 'DesignationList',

  components: {
    TitleButton,
    ListTable,
    DesignationAddModal,
    Pagination,
    Loader,
  },

  data: () => ({
    loading: false,
    quick_search: null,
    offset: 20,
    designationList: [],
    designation: {
      name: '',
      short_name: '',
      code: null,
    },
  }),

  computed: {
    companyId() {
      return this.$route.params.companyId;
    }
  },

  methods: {
    onClickAddNewButton() {
      this.$refs.designation.toggleModal()
    },

    onClickSettingButton() {
      alert('Setting Button clicked');
    },

    onClickSearchButton() {
      alert('Search Button clicked');
    },

    resetForm() {
      this.designation = {
        name: '',
        short_name: '',
        code: '',
        status: '',
      };
    },

    async handleDesignationSubmit() {
      this.loading = true

      this.designation.company_id = this.$route.params.companyId;
      this.designation.user_id = this.$store.getters["user/getUser"].id;
      this.designation.updated_by = this.$store.getters["user/getUser"].id;

      try {
        let res = null;

        if (this.designation.id) {
          res = await this.updateDesignation(this.designation.id, this.designation);
        } else {
          res = await this.createNewDesignation(this.designation);
        }

        if (!res.status) {
          this.showError(res.message);
        } else {
          this.showSuccess(res.message);
          this.resetForm();
          this.onClickAddNewButton();
          await this.getDesignations();
        }
      } catch (err) {
        if (!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        } else if (err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.loading = false;
      }
    },

    async getDesignations() {
      const query = this.getQuery();
      try {
        this.loading = true;
        let res = await this.fetchDesignationList(query);
        if (!res.status) {
          this.resetPagination();
        } else if (res.status) {
          this.designationList = res.data;
        }
      } catch (err) {
        if (err.response) {
          this.showError(err.response.message);
        }
      } finally {
        this.loading = false;
      }
    },

    getQuery() {
      let query = '?company_id=' + this.companyId;
      query += '&offset=' + this.offset;
      if (this.page) query += '&page=' + this.page;
      return query;
    },

    onPageChange(page) {
      let routeQuery = Object.assign({}, this.$route.query);
      routeQuery.page = page;
      this.$router.push({path: this.$route.path, query: routeQuery});
      setTimeout(() => {
        this.getDesignations();
      }, 100)
    },

    onEditDesignation(data) {
      this.designation = data;
      this.onClickAddNewButton();
    },

    exportTable() {
      //todo
    },
  },

  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const {
      loading,
      fetchDesignationList,
      createNewDesignation,
      updateDesignation,
    } = handleDesignations();

    return {
      loading,
      showSuccess,
      showError,
      fetchDesignationList,
      createNewDesignation,
      updateDesignation,
    }
  },

  mounted() {
    this.getDesignations();
  }
}
</script>
